<div class="modal-backdrop" (click)="disableBackdrop ? null : closeEmitter.emit()"></div>
<div class="modal {{ size }} flex-col">
	<button class="close-button" (click)="closeModal()">
		<i class="bi bi-x"></i>
	</button>
	<div class="modal-header">
		<h4>{{ title }}</h4>
	</div>
	<div class="modal-content flex-col">
		<p>{{ content }}</p>
		<ng-content></ng-content>
	</div>
	<div class="modal-footer flex-row">
		@for (button of buttons; track button) {
			<app-button
				[label]="button.label"
				(click)="onButtonClick(button.action, button.autoClose)"
				[extraClass]="button.label === 'Cancel' ? 'cancel' : ''"
			></app-button>
		}
	</div>
</div>