<article class="attribute-value-container flex-col">
	@if (managedObjectAttribute.id !== 0) {
		<section class="attribute-value-section flex-row">
			<h6 class="attribute-value-heading">Created by:</h6>
			<p class="attribute-value-static font-smaller">
				{{ CreatedByUser }}
			</p>
		</section>
	}
	<section class="attribute-value-section flex-col">
		<h6 class="attribute-value-heading">Description:</h6>
		@if (managedObjectAttribute.id === 0) {
			<app-input
				class="name-input"
				[small]="true"
				placeholder="Your description here..."
				[(model)]="managedObjectAttribute.description"
				name="description"
				[maxLength]="500"
			/>
		} @else if (isEditingDescription) {
			<app-input
				[(ngModel)]="managedObjectAttribute.description"
				[model]="managedObjectAttribute.description"
				(enterPressed)="onSaveEditDescription()"
				[maxLength]="500"
			></app-input>
			<section class="edit-btn-container">
				<app-confirm-cancel-button-group
					(confirm)="onSaveEditDescription()"
					(cancel)="onCancelEditDescription()"
				></app-confirm-cancel-button-group>
			</section>
		} @else {
			<p class="item edit-pen-container description-content">
				{{ managedObjectAttribute.description }} &nbsp;
				<img
					src="../../../../../assets/icon/pencil.svg"
					alt="Svg of a pencil"
					class="edit-pen"
					(click)="onEditDescription()"
					[ngClass]="readOnlyMode ? 'display-none' : ''"
				/>
			</p>
		}
	</section>
	<section class="grid-container">
		@for (
			managedObjectAttributeValue of managedObjectAttribute.managedObjectAttributeValues;
			track managedObjectAttributeValue
		) {
			<div
				[ngStyle]="{
					'grid-row': managedObjectAttributeValue.attributeValue.row,
					'grid-column':
						managedObjectAttributeValue.attributeValue.column +
						' / span ' +
						managedObjectAttributeValue.attributeValue.columnWidth,
				}"
			>
				@switch (managedObjectAttributeValue.attributeValue.type) {
					@case (AttributeType.URL) {
						<app-link
							[managedObjectAttributeValue]="managedObjectAttributeValue"
							[managedObjectAttribute]="managedObjectAttribute"
							(saveUrlChanges)="updateManagedObjectAttribute()"
						></app-link>
					}
					@case (AttributeType.File) {
						<app-file
							[managedObjectAttributeValue]="managedObjectAttributeValue"
							[managedObjectAttribute]="managedObjectAttribute"
							(saveFileChanges)="updateManagedObjectAttribute()"
						></app-file>
					}
					@case (AttributeType.Markdown) {
						<app-note
							[managedObjectAttributeValue]="managedObjectAttributeValue"
							[managedObjectAttribute]="managedObjectAttribute"
							[isExpanded]="managedObjectAttribute.isExpanded"
							(saveNoteChanges)="updateManagedObjectAttribute()"
						></app-note>
					}
				}
			</div>
		}
	</section>
	@if (managedObjectAttribute.id !== 0) {
		<section class="delete-btn-container" [ngClass]="readOnlyMode ? 'display-none' : ''">
			<app-button
				(buttonClick)="onDeleteAttribute()"
				label="Delete"
				[smallButton]="true"
			></app-button>
		</section>
	}
</article>
