<section
	class="dot-menu"
	[ngClass]="readOnlyMode ? 'display-none' : ''"
	(clickOutside)="onClickOutside($event)"
>
	<button (click)="toggleDotMenu()" class="default-btn padding-sides">
		<img src="assets/icon/three-dots-vertical.svg" alt="Three dots for item-menu" />
	</button>
	@if (showDotMenuOptions) {
		<ul class="dot-menu-option font-smaller border-radius">
			<ng-content></ng-content>
		</ul>
	}
</section>
