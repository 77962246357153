import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ToolboxButtonComponent } from './toolbox-button/toolbox-button.component';
import { ToolboxState, ToolboxAction } from '../../../core/models/deluxedraw';
import { ModalService } from '../../../core/services/modal.service';
import { ContextService } from '../../../core/services/context.service';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReadOnlyService } from '../../../core/services/read-only.service';
import { NgClass } from '@angular/common';

@Component({
	selector: 'app-toolbox',
	standalone: true,
	imports: [ToolboxButtonComponent, NgClass],
	templateUrl: './toolbox.component.html',
	styleUrl: './toolbox.component.css',
})
export class ToolboxComponent implements OnChanges {
	@Input() contextId: number | null = null;
	canCreateContext: boolean = true;

	@Output() selectedToolboxAction = new EventEmitter<{
		action: ToolboxAction;
	}>();

	ToolboxAction = ToolboxAction;
	ToolboxState = ToolboxState;
	toolboxState: ToolboxState = ToolboxState.Edit;
	selectedRelation: ToolboxAction = ToolboxAction.Connect;
	readOnlyMode: boolean = false;
	private isContextChanged: boolean = true;

	readonly #relationActions: ToolboxAction[] = [ToolboxAction.Connect, ToolboxAction.ConnectBoth];

	constructor(
		private contextService: ContextService,
		private modalService: ModalService,
		private router: Router,
		private readOnlyService: ReadOnlyService,
	) {
		this.readOnlyService.isReadOnly$.subscribe((value) => {
			this.readOnlyMode = value;
		});

		this.contextService.canCreateContext$
			.pipe(takeUntilDestroyed())
			.subscribe((canCreateContext) => {
				this.canCreateContext = canCreateContext;
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['contextId'] && this.contextId) {
			this.onToolboxButtonClick({ action: ToolboxAction.Edit, state: ToolboxState.Edit });
			this.isContextChanged = true;
		}
	}

	onToolboxButtonClick(event: { action: ToolboxAction; state: ToolboxState }): void {
		if (event.action === ToolboxAction.Box) {
			if (!this.canCreateContext) {
				this.modalService
					.open({
						title: 'No more available drawings',
						content:
							'Upgrade to create an unlimited number of drawings, or press "Cancel" to continue using the free version.',
						buttons: [
							{
								label: 'Become Pro',
								action: (): void => {
									void this.router.navigate(['/settings']);
								},
							},
							{
								label: 'Cancel',
								action: (): void => {},
							},
						],
					})
					.subscribe();
				return;
			}
		}
		this.processToolboxAction(event);
	}

	private processToolboxAction(event: { action: ToolboxAction; state: ToolboxState }): void {
		let actionToEmit = event.action;

		if (this.readOnlyMode && this.isContextChanged) {
			actionToEmit = ToolboxAction.Navigate;
			this.selectedRelation = this.#relationActions.includes(actionToEmit)
				? actionToEmit
				: this.selectedRelation;
		} else {
			this.selectedRelation = this.#relationActions.includes(event.action)
				? event.action
				: this.selectedRelation;
		}

		this.toolboxState = this.readOnlyMode ? ToolboxState.Navigate : event.state;

		this.selectedToolboxAction.emit({
			action: actionToEmit,
		});

		this.isContextChanged = false;
	}
}
