import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ReadOnlyService {
	private isReadOnly = new BehaviorSubject<boolean>(false);
	isReadOnly$: Observable<boolean> = this.isReadOnly.asObservable();

	setReadOnly(readOnly: boolean): void {
		this.isReadOnly.next(readOnly);
	}
}
