<li class="context-li">
	<div
		class="context-container gap-small flex-row"
		[ngClass]="{ 'selected-active': isActive }"
		(mouseenter)="showDotMenu = true"
		(mouseleave)="showDotMenu = false"
	>
		@if (!context.isEditing) {
			<button
				class="default-btn keep-scale"
				(click)="toggleExpansion()"
				[style.visibility]="context.children && context.children.length > 0 ? 'visible' : 'hidden'"
			>
				<img
					class="arrow-icon"
					[src]="
						context.expanded ? 'assets/icon/caret-down-fill.svg' : 'assets/icon/caret-right.svg'
					"
					alt="arrow icon"
				/>
			</button>
			<a
				class="ellipsis ellipsis-long-word highlighted context-name"
				[routerLink]="['/', context.id]"
			>
				{{ context.name }}
			</a>
			<section class="dot-menu-wrapper">
				@if (context.isLoading) {
					<app-loading-spinner></app-loading-spinner>
				} @else if (showDotMenu) {
					<app-dot-menu>
						<app-dot-menu-option
							label="Share"
							(action)="onCreateSharedDrawing()"
						></app-dot-menu-option>
						<app-dot-menu-option
							label="Rename"
							(click)="showRenameDrawingForm(context)"
						></app-dot-menu-option>
						<app-dot-menu-option label="Delete" (action)="onDeleteDrawing()"></app-dot-menu-option>
					</app-dot-menu>
				}
			</section>
		} @else {
			<app-input
				[(ngModel)]="name"
				[model]="context.name"
				(enterPressed)="onRenameDrawing()"
			></app-input>
			<app-confirm-cancel-button-group
				(cancel)="onCancelRenaming(context)"
				(confirm)="onRenameDrawing()"
				[disabled]="!name || !context.isEditing"
				[showLoadingSpinner]="context.isLoading"
			></app-confirm-cancel-button-group>
		}
	</div>
	<ul>
		@if (context.expanded && context.children && context.children.length > 0) {
			@for (child of context.children; track child) {
				<app-context-item [context]="child" [currentContext]="currentContext"></app-context-item>
			}
		}
	</ul>
</li>
