<section class="attribute-value-section flex-col attribute-type-file">
	<h6 class="attribute-value-heading">{{ managedObjectAttributeValue.attributeValue.name }}:</h6>
	<section class="attribute-value-section flex-col flex-item">
		@if (managedObjectAttributeValue.id === 0) {
			@if (!selectedFileName) {
				<app-input
					type="file"
					(change)="uploadNewFile($event)"
					[border]="false"
					[maxLength]="255"
				></app-input>
			}
			<p class="item ellipsis file">{{ selectedFileName }}</p>
			@if (isFileSelected) {
				<app-loading-spinner></app-loading-spinner>
			}
		} @else if (isEditing) {
			<section class="flex-row">
				<app-input
					type="file"
					[border]="false"
					(change)="uploadNewFile($event)"
					[maxLength]="255"
				></app-input>
				@if (isFileSelected) {
					<app-loading-spinner></app-loading-spinner>
				} @else {
					<section class="edit-btn-container flex-row">
						<app-confirm-cancel-button-group
							(cancel)="onCancelChanges()"
							(confirm)="onSaveChanges()"
						></app-confirm-cancel-button-group>
					</section>
				}
			</section>
		} @else {
			<section class="edit-pen-container">
				<li class="attribute-value-text ellipsis">
					<a class="item ellipsis file" (click)="downloadFile()">
						{{ managedObjectAttributeValue.value }} &nbsp;
					</a>
				</li>
				<img
					src="../../../../../assets/icon/pencil.svg"
					alt="Svg of a pencil"
					class="edit-pen"
					(click)="onEdit()"
					[ngClass]="readOnlyMode ? 'display-none' : ''"
				/>
			</section>
		}
	</section>
</section>
