import { Component, HostListener, ElementRef, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
	selector: 'app-dot-menu',
	standalone: true,
	imports: [NgClass],
	templateUrl: './dot-menu.component.html',
	styleUrl: './dot-menu.component.css',
})
export class DotMenuComponent {
	showDotMenuOptions: boolean = false;

	@Input() readOnlyMode?: boolean = false;

	constructor(private elementRef: ElementRef) {}

	toggleDotMenu(): void {
		this.showDotMenuOptions = !this.showDotMenuOptions;
	}

	@HostListener('document:click', ['$event.target'])
	onClickOutside(target: Event): void {
		if (this.showDotMenuOptions) {
			const clickedInside = this.elementRef.nativeElement.contains(target);
			if (!clickedInside) {
				this.showDotMenuOptions = false;
			}
		}
	}
}
