import { NgClass } from '@angular/common';
import { Component, Input, Output, forwardRef, EventEmitter } from '@angular/core';
import {
	FormsModule,
	NG_VALUE_ACCESSOR,
	ControlValueAccessor,
	NG_VALIDATORS,
	ValidationErrors,
} from '@angular/forms';

@Component({
	selector: 'app-input',
	standalone: true,
	imports: [FormsModule, NgClass],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => InputComponent),
			multi: true,
		},
	],

	templateUrl: './input.component.html',
	styleUrls: ['./input.component.css', '../../../../attribute-value-styles.css'],
})
export class InputComponent implements ControlValueAccessor {
	@Input() labelText: string = '';
	@Input() type: string = 'text';
	@Input() placeholder: string = '';
	@Input() name: string = '';
	@Input() model: string = '';
	@Input() small: boolean = false;
	@Input() border?: boolean = true;
	@Input() class?: string = '';
	@Input() maxLength?: number = 50;
	@Input() toLowerCase?: boolean = false;
	isLengthValid: boolean = false;

	@Output() modelChange = new EventEmitter<string>();
	@Output() enterPressed = new EventEmitter();
	@Output() blurred = new EventEmitter<void>();

	get id(): string {
		return this.name ? this.name + '_id' : '';
	}

	onChange: (value: string | number) => void = () => {};
	onTouched: () => void = () => {};

	writeValue(): void {}

	registerOnChange(fn: (value: string | number) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	validate(): ValidationErrors | null {
		return null;
	}

	handleInput(event: Event): void {
		const input = event.target as HTMLInputElement;
		this.isLengthValid = input.value.length >= this.maxLength!;
		this.onChange(input.value);
		this.onTouched();
		let value = input.value;
		if (this.toLowerCase) {
			value = value.toLowerCase();
		}
		input.value = value;
		this.modelChange.emit(value);
	}

	onEnter(): void {
		this.enterPressed.emit();
	}

	onBlur(): void {
		this.blurred.emit();
	}
}
