import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	ManagedObjectAttribute,
	ManagedObjectAttributeValue,
} from '../../../../core/models/managed-object';
import { NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MarkdownEditorComponent } from '../../../../shared/markdown-editor/markdown-editor.component';
import { ConfirmCancelButtonGroupComponent } from '../../../../shared/buttons-confirm-cancel/confirm-cancel-button-group.component';
import { SafeHtmlPipe } from '../../../../core/pipes/safe-html.pipe';
import { ReadOnlyService } from '../../../../core/services/read-only.service';

@Component({
	selector: 'app-note',
	standalone: true,
	imports: [
		NgClass,
		FormsModule,
		MarkdownEditorComponent,
		ConfirmCancelButtonGroupComponent,
		SafeHtmlPipe,
	],
	templateUrl: './note.component.html',
	styleUrls: ['./note.component.css', '../../../../../attribute-value-styles.css'],
})
export class NoteComponent implements OnInit {
	@Input() managedObjectAttributeValue!: ManagedObjectAttributeValue;
	@Input() managedObjectAttribute!: ManagedObjectAttribute;
	@Input() isExpanded!: boolean;

	@Output() saveNoteChanges = new EventEmitter();

	isEditing: boolean = false;
	markdownContent: string = '';
	markdownContentChanged: boolean = false;
	readOnlyMode: boolean = false;

	constructor(private readOnlyService: ReadOnlyService) {}

	ngOnInit(): void {
		this.markdownContent = this.managedObjectAttributeValue.value;

		this.readOnlyService.isReadOnly$.subscribe((value) => {
			this.readOnlyMode = value;
		});
	}

	onEdit(): void {
		this.isEditing = true;
	}

	onMarkdownContentChange(inputValue: string): void {
		this.markdownContent = inputValue;
		this.markdownContentChanged = true;
	}

	onSaveChanges(): void {
		if (this.markdownContentChanged) {
			this.managedObjectAttributeValue.value = this.markdownContent;
			this.saveNoteChanges.emit();
		}
		this.isEditing = false;
		this.markdownContentChanged = false;
	}

	onCancelEdit(): void {
		this.isEditing = false;
	}

	onCreateNewNotes(content: string): void {
		this.managedObjectAttributeValue.value = content;
		this.managedObjectAttribute.hasAttributeValue = true;
	}
}
