<section
	[ngClass]="[extraClass, 'btn-container']"
	(click)="onClick()"
	(mouseenter)="handleMouseEnter()"
	(mouseleave)="handleMouseLeave()"
>
	<i [ngClass]="[iconClass, 'font-bigger', 'toolbox-icon']"></i>
</section>

@if (showTooltip) {
	<p class="tooltip font-smaller capitalize">{{ tooltip }}</p>
}
