import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	ManagedObjectAttribute,
	ManagedObjectAttributeValue,
} from '../../../../core/models/managed-object';
import { AttributeService } from '../../../../core/services/attribute.service';
import { MetaDataResponse } from '../../../../core/models/attribute/meta-data-response';
import { FormsModule } from '@angular/forms';
import { InputComponent } from '../../../../shared/input-field/input/input.component';
import { ConfirmCancelButtonGroupComponent } from '../../../../shared/buttons-confirm-cancel/confirm-cancel-button-group.component';
import { MetaDataComponent } from './meta-data/meta-data.component';
import { ToastrService } from '../../../../core/services/toastr.service';
import { ReadOnlyService } from '../../../../core/services/read-only.service';
import { NgClass } from '@angular/common';

@Component({
	selector: 'app-link',
	standalone: true,
	imports: [
		FormsModule,
		InputComponent,
		ConfirmCancelButtonGroupComponent,
		MetaDataComponent,
		NgClass,
	],
	templateUrl: './link.component.html',
	styleUrls: ['./link.component.css', '../../../../../attribute-value-styles.css'],
})
export class LinkComponent implements OnInit {
	@Input() managedObjectAttributeValue!: ManagedObjectAttributeValue;
	@Input() managedObjectAttribute!: ManagedObjectAttribute;

	@Output() saveUrlChanges = new EventEmitter();

	isEditing: boolean = false;
	urlContent: string = '';
	metaData: MetaDataResponse | undefined;
	readOnlyMode: boolean = false;

	constructor(
		private attributeService: AttributeService,
		private toastrService: ToastrService,
		private readOnlyService: ReadOnlyService,
	) {}

	ngOnInit(): void {
		this.urlContent = this.managedObjectAttributeValue.value;
		this.getMetaData(this.urlContent);

		this.readOnlyService.isReadOnly$.subscribe((value) => {
			this.readOnlyMode = value;
		});
	}

	onManagedObjectAttributeValueChanged(value: string): void {
		if (value) {
			this.managedObjectAttributeValue.value = value;
			this.managedObjectAttribute.hasAttributeValue = true;
		} else this.managedObjectAttribute.hasAttributeValue = false;
	}

	addHttpsIfMissing(): void {
		const value = this.managedObjectAttributeValue.value;
		if (value && !value.startsWith('https://') && !value.startsWith('http://')) {
			this.managedObjectAttributeValue.value = 'https://' + value;
		}
	}

	getMetaData(url: string): void {
		if (this.isValidUrl(url)) {
			this.attributeService.getMetaData(url).subscribe({
				next: (metaData) => {
					this.metaData = metaData;
				},
				error: () => {
					if (this.metaData) {
						this.toastrService.showError({});
					}
				},
			});
		}
	}

	isValidUrl(url: string): boolean {
		try {
			new URL(url);
			return true;
		} catch (e) {
			return false;
		}
	}

	onEdit(): void {
		this.isEditing = true;
	}

	onSaveChanges(): void {
		if (this.managedObjectAttributeValue.value !== this.urlContent) {
			this.urlContent = this.managedObjectAttributeValue.value;
			this.addHttpsIfMissing();
			this.managedObjectAttribute.hasAttributeValue = true;
		} else this.managedObjectAttribute.hasAttributeValue = false;

		this.saveUrlChanges.emit();
		this.isEditing = false;
	}

	onCancelChanges(): void {
		this.isEditing = false;
	}
}
