import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import { DotMenuComponent } from '../../shared/dot-menu/dot-menu.component';
import { DotMenuOptionComponent } from '../../shared/dot-menu/dot-menu-option/dot-menu-option.component';
import { AttributeComponent } from '../attribute/attribute.component';
import { InputComponent } from '../../shared/input-field/input/input.component';
import { Folder } from '../../core/models/folders';
import { ConfirmCancelButtonGroupComponent } from '../../shared/buttons-confirm-cancel/confirm-cancel-button-group.component';
import { FormsModule } from '@angular/forms';
import { AttributeMenuComponent } from '../attribute/attribute-menu/attribute-menu.component';
import { Attribute } from '../../core/models/attribute';
import { ContentViewStateService } from '../../core/services/content-view-state.service';

@Component({
	selector: 'app-folder',
	standalone: true,
	imports: [
		FormsModule,
		LoadingSpinnerComponent,
		DotMenuComponent,
		DotMenuOptionComponent,
		AttributeComponent,
		InputComponent,
		InputComponent,
		ConfirmCancelButtonGroupComponent,
		AttributeMenuComponent,
	],
	templateUrl: './folder.component.html',
	styleUrl: './folder.component.css',
})
export class FolderComponent implements OnInit {
	@Input() folder!: Folder;
	@Input() isLoading: boolean = false;
	@Input() attributes: Attribute[] | undefined;
	@Input() readOnlyMode: boolean = false;

	@Output() renameFolder = new EventEmitter<void>();
	@Output() deleteFolder = new EventEmitter<number>();

	newFolderName: string = '';
	showDotMenu: boolean = false;

	constructor(private contentViewStateService: ContentViewStateService) {}

	ngOnInit(): void {
		this.folder.isExpanded = this.contentViewStateService.isFolderExpanded(this.folder.id);
	}

	onToggleExpansion(): void {
		if (this.folder.isEditing) return;

		this.folder.isExpanded = !this.folder.isExpanded;

		this.contentViewStateService.setFolderExpanded(this.folder.id, this.folder.isExpanded);
	}

	onExpandAllAttributes(): void {
		this.folder.managedObjectAttributes.forEach((attribute) => {
			if (attribute.id !== 0) {
				attribute.isExpanded = true;
				this.contentViewStateService.setManagedObjectAttributeExpanded(
					attribute.id,
					attribute.isExpanded,
				);
			}
		});
	}

	onCollapseAllAttributes(): void {
		this.folder.managedObjectAttributes.forEach((attribute) => {
			if (attribute.id !== 0) {
				attribute.isExpanded = false;
				this.contentViewStateService.setManagedObjectAttributeExpanded(
					attribute.id,
					attribute.isExpanded,
				);
			}
		});
	}

	showRenameFolderForm(): void {
		this.folder.isEditing = true;
	}

	onRenameFolder(): void {
		this.folder.name = this.newFolderName;
		this.renameFolder.emit();
	}

	onCancelRenaming(): void {
		this.folder.isEditing = false;
		this.newFolderName = '';
	}

	onDeleteFolder(): void {
		this.deleteFolder.emit(this.folder.id);
	}
}
