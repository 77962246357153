import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoadingSpinnerComponent } from '../../loading-spinner/loading-spinner.component';
import { FormsModule } from '@angular/forms';

@Component({
	selector: 'app-button',
	standalone: true,
	imports: [NgClass, LoadingSpinnerComponent, FormsModule],
	templateUrl: './button.component.html',
	styleUrl: './button.component.css',
})
export class ButtonComponent {
	@Input() label: string = '';
	@Input() type: string = 'button';
	@Input() extraClass: string = '';
	@Input() smallButton?: boolean = false;
	@Input() showLoadingSpinner?: boolean = false;
	@Input() disabled?: boolean = false;

	@Output() buttonClick = new EventEmitter<void>();

	onClick(): void {
		if (!this.disabled) {
			this.buttonClick.emit();
		}
	}
}
