import {
	Component,
	Output,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	SimpleChanges,
	AfterViewInit,
} from '@angular/core';
import { ToolboxComponent } from './toolbox/toolbox.component';
import { SideBarStateService } from '../../core/services/side-bar-state.service';
import { DeluxeDraw } from '@boswell/deluxedraw';
import { DeluxeDrawService } from '../../core/services/deluxe-draw.service';
import { Side, SideDragged } from '../../core/models/sidebar/index';
import { catchError, of, tap } from 'rxjs';
import { ContextService } from '../../core/services/context.service';
import { NodeService } from '../../core/services/node-service';
import { NgClass } from '@angular/common';
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import {
	nodeDefaultColor,
	nodeHighlightShadow,
	nodeHightlightColor,
} from '../../../assets/svg/svg-colors';
import { ShapeType } from '../../core/models/deluxedraw/shape-type';
import { UpdateNodeProperties } from '../../core/models/managed-object-node/update-properties';
import { RelationService } from '../../core/services/relation-service';
import { ToolboxAction } from '../../core/models/deluxedraw';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalService } from '../../core/services/modal.service';
import { ToastrService } from '../../core/services/toastr.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReadOnlyService } from '../../core/services/read-only.service';

interface DeluxeDrawNode {
	svg: SVGElement;
	id: number;
}

@Component({
	selector: 'app-deluxe-draw',
	standalone: true,
	imports: [ToolboxComponent, LoadingSpinnerComponent, NgClass],
	templateUrl: './deluxe-draw.component.html',
	styleUrl: './deluxe-draw.component.css',
})
export class DeluxeDrawComponent implements OnChanges, AfterViewInit {
	@Input() contextId: number = 0;

	@Output() toggleSideBar = new EventEmitter<boolean>();
	@Output() toggleContentView = new EventEmitter<boolean>();
	@Output() reSize = new EventEmitter<SideDragged>();
	@Output() contextSelectedChange = new EventEmitter<boolean>();

	private toolboxAction: ToolboxAction;

	deluxeDraw: DeluxeDraw;
	Side = Side;
	draggingSide: Side = Side.Left;
	clickedNode: DeluxeDrawNode | undefined;
	currentNodeId: number | undefined;
	readOnlyMode: boolean = false;

	isContextSelected: boolean = false;
	isLoadingContext: boolean = false;
	isDeleting: boolean = false;

	constructor(
		private sideBarStateService: SideBarStateService,
		private deluxeDrawService: DeluxeDrawService,
		private nodeService: NodeService,
		private contextService: ContextService,
		private relationService: RelationService,
		private modalService: ModalService,
		private toastrService: ToastrService,
		private readOnlyService: ReadOnlyService,
	) {
		this.deluxeDraw = new DeluxeDraw();

		this.readOnlyService.isReadOnly$.subscribe((value) => {
			this.readOnlyMode = value;
		});

		this.toolboxAction = this.readOnlyMode ? ToolboxAction.Navigate : ToolboxAction.Edit;

		this.nodeService
			.updatedNodeName$()
			.pipe(takeUntilDestroyed())
			.subscribe({
				next: ({ id, name, nodeType }) => {
					this.deluxeDraw.updateNodeName(id, name, nodeType);
				},
				error: () => {
					this.toastrService.showError({});
				},
			});

		this.nodeService
			.deleteContextNode$()
			.pipe(takeUntilDestroyed())
			.subscribe({
				next: ({ referenceId, nodeType }) => {
					this.deluxeDraw.deleteContextNode(referenceId, nodeType);
				},
				error: () => {
					this.toastrService.showError({});
				},
			});
	}

	ngAfterViewInit(): void {
		this.deluxeDraw.init();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['contextId'] && this.contextId) {
			this.loadContext(this.contextId);
		}
	}

	loadContext(contextId: number): void {
		this.isLoadingContext = true;
		this.contextService.get(contextId).subscribe({
			next: (context) => {
				this.isLoadingContext = false;
				this.deluxeDraw.openContext(context, this.toolboxAction);
				this.isContextSelected = true;
			},
			error: () => {
				this.isLoadingContext = false;
				this.toastrService.showError({});
			},
		});
	}

	addClickedNodeStyle(node: DeluxeDrawNode): void {
		node.svg.setAttribute('stroke', nodeHightlightColor);
		node.svg.setAttribute('filter', nodeHighlightShadow);
	}

	removeClickedNodeStyle(node: DeluxeDrawNode): void {
		node.svg.setAttribute('stroke', nodeDefaultColor);
		node.svg.setAttribute('filter', 'none');
	}

	@HostListener('document:nodeClickedCommand', ['$event'])
	onNodeClicked(event: CustomEvent): void {
		if (this.clickedNode && this.clickedNode.id !== event.detail.node.id) {
			this.removeClickedNodeStyle(this.clickedNode!);
		}
		this.clickedNode = event.detail.node;
		this.currentNodeId = event.detail.node.id;
		this.addClickedNodeStyle(this.clickedNode!);
		this.deluxeDrawService.nodeClicked(event.detail.node);
		this.sideBarStateService.toggleContentView(true);
	}

	@HostListener('document:nodeDragged', ['$event'])
	nodeDrag(event: CustomEvent): void {
		const { id, positionX, positionY, shape } = event.detail;
		const shapeNumber = this.getShapeType(shape);
		const properties: UpdateNodeProperties = {
			id,
			positionX,
			positionY,
			shape: shapeNumber,
		};
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const self = this;
		this.nodeService.update(properties).subscribe({
			error() {
				self.toastrService.showError({});
			},
		});
	}

	@HostListener('document:tryRemoveNodeCommand', ['$event'])
	nodeRemoved(event: CustomEvent): void {
		if (this.isDeleting) return;

		this.isDeleting = true;
		this.deluxeDrawService
			.removeNode(event.detail.node)
			.pipe(
				tap(() => {
					this.deluxeDraw.removeNode(event.detail.node);
					this.isDeleting = false;

					if (event.detail.node.id === this.currentNodeId)
						this.sideBarStateService.toggleContentView(false);
				}),
				catchError((httpErrorResponse: HttpErrorResponse) => {
					if (httpErrorResponse.status === 409) {
						return this.modalService.open({
							title: 'Node could not be deleted',
							content: httpErrorResponse.error.detail,
							buttons: [
								{
									label: 'Ok',
									action: (): void => {},
								},
							],
						});
					}
					return of(undefined);
				}),
			)
			.subscribe({
				next: (_) => {
					this.isDeleting = false;
				},
			});
	}

	@HostListener('document:nodeCreated', ['$event'])
	nodeCreated(event: CustomEvent): void {
		this.deluxeDrawService
			.createNode({
				guid: event.detail.guid,
				name: event.detail.name,
				positionX: event.detail.positionX,
				positionY: event.detail.positionY,
				shape: event.detail.shape,
				nodeType: event.detail.nodeType,
				contextId: this.contextId!,
			})
			.pipe(
				tap((response) => {
					this.deluxeDraw.updateNodeAndReferenceId(
						response.referenceId,
						response.nodeId,
						event.detail.guid,
					);
				}),
			)
			.subscribe();
	}

	@HostListener('document:createRelation', ['$event'])
	createRelation(event: CustomEvent): void {
		this.relationService
			.createRelation({
				guid: event.detail.guid,
				symbol1: event.detail.symbol1,
				symbol2: event.detail.symbol2,
				anchorLocation1: event.detail.anchorLocation1,
				anchorLocation2: event.detail.anchorLocation2,
				node1Id: event.detail.node1Id,
				node2Id: event.detail.node2Id,
			})
			.subscribe({
				next: (response) => {
					this.deluxeDraw.updateRelation(response.id, event.detail.guid);
				},
				error: () => {
					this.toastrService.showError({});
				},
			});
	}

	@HostListener('document:deleteRelationCommand', ['$event'])
	relationRemoved(event: CustomEvent): void {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const self = this;
		this.relationService.removeRelation(event.detail.relation.id).subscribe({
			error() {
				self.toastrService.showError({});
			},
		});
	}

	onSelectedToolboxAction(event: { action: ToolboxAction }): void {
		if (event.action) {
			this.toolboxAction = event.action;
			this.deluxeDraw.onToolboxAction(this.toolboxAction);
		}
	}

	onSideBarToggleClick(): void {
		this.sideBarStateService.toggleSideBar();
	}

	onContentViewToggleClick(): void {
		this.sideBarStateService.toggleContentView();
	}

	onDrag(event: DragEvent, side: Side): void {
		if (!event.clientX) return;
		this.reSize.emit({ newWidth: this.getNewWidth(event, side), side: side });
	}

	private getNewWidth(event: DragEvent, side: Side): number {
		return side === Side.Left
			? event.clientX < this.sideBarStateService.minWidthSidebar
				? this.sideBarStateService.minWidthSidebar - 1
				: event.clientX
			: event.clientX < this.sideBarStateService.minWidthContentView
				? this.sideBarStateService.minWidthContentView - 1
				: event.clientX;
	}

	private getShapeType(shape: string): number {
		return ShapeType[shape as keyof typeof ShapeType];
	}
}
