import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class SharingService {
	private baseUrl: string = `${environment.apiUrl}sharing/api/sharing`;

	constructor(private httpClient: HttpClient) {}

	createSharedContext(contextId: number): Observable<string> {
		return this.httpClient.post(
			`${this.baseUrl}/shared-context`,
			{ contextId },
			{ responseType: 'text' },
		);
	}
}
