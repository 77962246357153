@if (metaData) {
	<a [href]="url" target="_blank">
		<div class="meta-content flex-col gap-small">
			<section>
				@if (metaData.iconUrl) {
					<img class="meta-icon" [src]="metaData.iconUrl" alt="Link Preview Image" />
				}
				<b class="font-smaller">{{ metaData.title }}</b>
			</section>
			<p class="meta-description font-smaller">
				{{ metaData.description }}
			</p>
		</div>
	</a>
}
