import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ButtonComponent } from '../../../shared/button/button/button.component';
import { LoadingSpinnerComponent } from '../../../shared/loading-spinner/loading-spinner.component';
import { Router } from '@angular/router';
import { ContextService } from '../../../core/services/context.service';
import { InputComponent } from '../../../shared/input-field/input/input.component';
import { ConfirmCancelButtonGroupComponent } from '../../../shared/buttons-confirm-cancel/confirm-cancel-button-group.component';
import { ContextView } from '../../../core/models/contexts';
import { switchMap } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { NodeType } from '../../../core/models/deluxedraw/node-type';
import { NodeService } from '../../../core/services/node-service';
import { RenameNode } from '../../../core/models/context-node/rename-node';
import { ToastrService } from '../../../core/services/toastr.service';
import { SideBarStateService } from '../../../core/services/side-bar-state.service';
import { ReadOnlyService } from '../../../core/services/read-only.service';
import { NgClass } from '@angular/common';

@Component({
	selector: 'app-content-view-context',
	standalone: true,
	imports: [
		ButtonComponent,
		LoadingSpinnerComponent,
		InputComponent,
		ConfirmCancelButtonGroupComponent,
		FormsModule,
		NgClass,
	],
	templateUrl: './content-view-context.component.html',
	styleUrl: '../content-view.component.css',
})
export class ContentViewContextComponent implements OnChanges, OnInit {
	currentContext: ContextView | undefined;
	newContextName: string = '';
	readOnlyMode: boolean = false;

	@Input() contextId!: number;

	constructor(
		private contextService: ContextService,
		private nodeService: NodeService,
		private toastrService: ToastrService,
		private sideBarStateService: SideBarStateService,
		private router: Router,
		private readOnlyService: ReadOnlyService,
	) {}

	ngOnInit(): void {
		this.nodeService.updatedNodeName$().subscribe({
			next: (updatedNode) => {
				if (this.contextId === updatedNode.id) this.currentContext!.name = updatedNode.name;
			},
			error: () => {
				this.toastrService.showError({});
			},
		});

		this.nodeService.deleteContextNode$().subscribe({
			next: (deleteContextNode) => {
				if (this.contextId === deleteContextNode.referenceId)
					this.sideBarStateService.toggleContentView(false);
			},
			error: () => {
				this.toastrService.showError({});
			},
		});

		this.readOnlyService.isReadOnly$.subscribe((value) => {
			this.readOnlyMode = value;
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['contextId'] && this.contextId !== undefined) {
			this.contextService.get(this.contextId).subscribe({
				next: (context) => {
					this.currentContext = {
						...context,
						isLoading: false,
						isEditing: false,
						isSelected: false,
						expanded: false,
						readOnly: false,
						children: [],
					};
				},
				error: () => {
					this.toastrService.showError({});
				},
			});
		}
	}

	onShowEditForm(): void {
		if (!this.readOnlyMode) {
			this.validateCurrentContextOrThrow();
			this.currentContext!.isEditing = true;
		}
	}

	onRenameContext(): void {
		this.validateCurrentContextOrThrow();
		this.currentContext!.isLoading = true;
		this.currentContext!.name = this.newContextName;

		this.contextService
			.update(this.currentContext!.id, this.currentContext!.name, this.currentContext!.description)
			.pipe(switchMap(() => this.contextService.get(this.currentContext!.id)))
			.subscribe({
				next: (ctx) => {
					this.currentContext = {
						...ctx,
						isLoading: false,
						isEditing: false,
						isSelected: this.currentContext!.isSelected,
						expanded: this.currentContext!.expanded,
						children: this.currentContext!.children,
						readOnly: this.currentContext!.readOnly,
					};
					this.nodeService.renameNode({
						name: ctx.name,
						id: ctx.id,
						nodeType: NodeType.Context,
					} as RenameNode);
				},
				error: () => {
					this.toastrService.showError({});
				},
			});
	}

	onCancelRenamingContext(): void {
		this.validateCurrentContextOrThrow();

		this.currentContext!.isEditing = false;
		this.newContextName = '';
	}

	navigateToContext(): void {
		this.validateCurrentContextOrThrow();
		void this.router.navigate(['/', this.currentContext!.id]);
	}

	private validateCurrentContextOrThrow(): void {
		if (!this.currentContext) throw new Error('currentContext undefined');
	}

	closeContentView(): void {
		this.sideBarStateService.toggleContentView(false);
	}
}
