import {
	AfterViewChecked,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { Attribute } from '../../../core/models/attribute';
import { AttributeService } from '../../../core/services/attribute.service';
import { ManagedObjectAttribute } from '../../../core/models/managed-object';
import { ReadOnlyService } from '../../../core/services/read-only.service';
import { NgClass } from '@angular/common';

@Component({
	selector: 'app-attribute-menu',
	standalone: true,
	imports: [NgClass],
	templateUrl: './attribute-menu.component.html',
	styleUrl: './attribute-menu.component.css',
})
export class AttributeMenuComponent implements AfterViewChecked, OnChanges {
	showCreateAttributeMenu: boolean = false;
	showExpandTooltip: boolean = false;
	showCollapseTooltip: boolean = false;
	timeout: ReturnType<typeof setTimeout> | undefined;
	mouseEvent: MouseEvent | null = null;
	showAttributeTooltip: { [key: number]: boolean } = {};
	validManagedObjectAttributes: ManagedObjectAttribute[] = [];
	readOnlyMode: boolean = false;

	@ViewChild('menu') menu!: ElementRef;
	@Input() attributes: Attribute[] | undefined;
	@Input() managedObjectAttributes: ManagedObjectAttribute[] | undefined;
	@Input() folderId: number = 0;
	@Output() expandAllAttributes = new EventEmitter<boolean>();
	@Output() collapseAllAttributes = new EventEmitter<boolean>();

	constructor(
		private elementRef: ElementRef,
		private attributeService: AttributeService,
		private readOnlyService: ReadOnlyService,
	) {
		this.readOnlyService.isReadOnly$.subscribe((value) => {
			this.readOnlyMode = value;
		});
	}

	ngAfterViewChecked(): void {
		if (this.showCreateAttributeMenu && this.mouseEvent) {
			this.openAttributeMenu(this.mouseEvent);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['managedObjectAttributes'] && this.managedObjectAttributes!.length > 0) {
			this.checkValidManagedObjectAttributeList();
		}
	}

	checkValidManagedObjectAttributeList(): void {
		this.validManagedObjectAttributes =
			this.managedObjectAttributes?.filter(
				(managedObjectAttribute) => managedObjectAttribute.id !== 0,
			) || [];
	}

	onToggleShowCreateAttributeMenu(event: MouseEvent): void {
		this.showCreateAttributeMenu = !this.showCreateAttributeMenu;
		this.mouseEvent = event;
	}

	openAttributeMenu(event: MouseEvent): void {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const newItemPosition = (event.target as any).getBoundingClientRect();

		const attributeMenuElement = this.menu.nativeElement;

		if (attributeMenuElement) {
			let left = newItemPosition.x;
			let top = newItemPosition.y + 30;

			if (newItemPosition.x + attributeMenuElement!.offsetWidth > window.innerWidth)
				left = window.innerWidth - attributeMenuElement!.offsetWidth - 10;

			if (newItemPosition.y + attributeMenuElement!.offsetHeight > window.innerHeight)
				top = newItemPosition.y - attributeMenuElement!.offsetHeight;

			if (top < 0) top = 10;

			attributeMenuElement.style.left = `${left}px`;
			attributeMenuElement.style.top = `${top}px`;
		}
	}

	handleMouseEnter(buttonType: string): void {
		this.timeout = setTimeout(() => {
			if (buttonType === 'expand') this.showExpandTooltip = true;
			else this.showCollapseTooltip = true;
		}, 1000);
	}

	handleMouseLeave(buttonType: string): void {
		clearTimeout(this.timeout);
		if (buttonType === 'expand') this.showExpandTooltip = false;
		else this.showCollapseTooltip = false;
	}

	handleMouseEnterAttribute(i: number): void {
		this.timeout = setTimeout(() => {
			this.showAttributeTooltip[i] = true;
		}, 1000);
	}

	handleMouseLeaveAttribute(i: number): void {
		clearTimeout(this.timeout);
		this.showAttributeTooltip[i] = false;
	}

	onExpandAllAttributes(): void {
		this.expandAllAttributes.emit();
	}

	onCollapseAllAttributes(): void {
		this.collapseAllAttributes.emit();
	}

	onCreateAttribute(attribute: Attribute, i: number): void {
		this.showAttributeTooltip[i] = false;

		const alreadyCreating = this.managedObjectAttributes?.find(
			(managedObjectAttribute) => managedObjectAttribute.id === 0,
		);
		if (alreadyCreating) this.attributeService.closeManagedObjectAttribute(alreadyCreating);

		this.showCreateAttributeMenu = false;
		this.attributeService.initalizeManagedObjectAttribute(attribute, this.folderId);
	}

	@HostListener('document:click', ['$event.target'])
	onClickOutside(target: Event): void {
		if (this.showCreateAttributeMenu) {
			const clickedInside = this.elementRef.nativeElement.contains(target);
			if (!clickedInside) this.showCreateAttributeMenu = false;
		}
	}
}
